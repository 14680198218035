import { HYDRATE } from 'next-redux-wrapper';
import { diff } from 'jsondiffpatch';

const initialState = {
  email: '',
  password: '',
  authenticatorConnected: false,
  emailVerified: false,
  userStatus: ''
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      const stateDiff = diff(state, action.payload.auth);
      // structure of stateDiff: email: [ 'str', int, int ]...

      let wasBumpedOnClient = false;

      if (stateDiff && action.payload) {
        const { email, password, authenticatorConnected, emailVerified, userStatus } = stateDiff[0] || stateDiff;
        wasBumpedOnClient = email.length >= 0 || password.length >= 0 || authenticatorConnected || emailVerified || userStatus;
      }

      return {
        ...state,
        email: wasBumpedOnClient ? state.email : action.payload.auth.email,
        password: wasBumpedOnClient ? state.password : action.payload.auth.password,
        authenticatorConnected: wasBumpedOnClient ? state.authenticatorConnected : action.payload.auth.authenticatorConnected,
        emailVerified: wasBumpedOnClient ? state.emailVerified : action.payload.auth.emailVerified,
        userStatus: wasBumpedOnClient ? state.userStatus : action.payload.auth.userStatus
      };
    }

    // AUTH REDUCER
    case 'LOGIN_WITH_CREDENTIALS_ONLY_FULFILLED': {
      return {
        ...state,
        ...action.payload
      };
    }

    case 'EQUALIZE_STATE_WITH_SESSION_FULFILLED': {
      return {
        ...state,
        ...action.payload
      };
    }

    case 'LOGOUT_WHILE_IN_AUTHENTICATOR_SCREEN_FULFILLED': {
      return {
        ...state,
        email: '',
        password: ''
      };
    }

    default:
      return state;
  }
};
