import { applyMiddleware, createStore, combineReducers } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import { auth } from './auth';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { wrapMakeStore, nextReduxCookieMiddleware } from 'next-redux-cookie-wrapper';

// create a makeStore function
const makeStore = wrapMakeStore(() => createStore(
  combineReducers({
    auth
  }),
  applyMiddleware(
    logger,
    thunk,
    promise,
    nextReduxCookieMiddleware({
      subtrees: [
        'auth'
      ]
    })
  )
));

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, { debug: process.env.NODE_ENV !== 'production' });
