import 'antd/dist/antd.css';
import '../styles/vars.css';
import '../styles/global.css';
import Head from 'next/head';
import Header from '../components/header';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'next-auth/client';
import { wrapper } from '../components/redux/store';

class App extends Component {
  static async getInitialProps({ Component, ctx }) {
    return {
      pageProps: Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {},
      path: ctx.pathname
    };
  }

  state = {
    width: 0,
    height: 0
  };
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  render() {
    const { Component, pageProps } = this.props;

    return (
      <Provider session={pageProps.session}>
        <Head>
          <title>Berify - Portal</title>
          <link rel='shortcut icon' type='image/png' href='/Berify-logo.png' />
        </Head>
        <Header {...this.state} />
        <Component {...pageProps} {...this.state} />
      </Provider>
    );
  }
}

App.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.instanceOf(Component), PropTypes.func]),
  pageProps: PropTypes.object
};

export default wrapper.withRedux(App);
