export const getHeaderWidth = (width) => {
  if (width >= 1400) return 1400;
  if (width >= 1200) return 1200;
  if (width >= 1000) return 1000;
  if (width >= 800) return 800;
  return '100%';
};

export const getBodyWidth = (width) => {
  if (width >= 1000) return 1000;
  if (width >= 800) return 800;
  return '100%';
};

/**
 * Replace char at index for string 'str' with 'ch' 
 * @param {String} str 
 * @param {Number} index 
 * @param {String} ch 
 * @returns 
 */
export const replaceAt = (str, index, ch = ' ') => {
  if (ch.length !== 1) ch = ' ';
  return str.replace(/./g, (c, i) => i == index ? ch : c);
};
