import { Typography } from 'antd';

const { Text } = Typography;

export const Logo = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div>
        <img
          src='/Berify-logo.png'
          style={{ width: 30, marginRight: 12 }}
        />
      </div>
      <div>
        <Text style={{ fontSize: 21, color: '#C753FE' }} strong>
          Portal
        </Text>
      </div>
    </div>
  );
};

export default Logo;
