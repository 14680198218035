import React from 'react';
import Link from 'next/link';
import { Avatar, Typography, Button, Menu, Dropdown } from 'antd';
import { getHeaderWidth } from './functions';
import PropTypes from 'prop-types';
import { signIn, signOut, useSession } from 'next-auth/client';
import { useRouter } from 'next/router';
import Logo from './logo';
import { UserOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const Header = (props) => {
  const [session] = useSession();
  const router = useRouter();

  const handleMenuClick = (e) => {
    // extract menu key
    const { key } = e;

    if (key === 'logout') {
      signOut({ redirect: false, callbackUrl: '/' });
      router.push('/');
    }
  };

  const DropdownMenuItems = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='logout'>Log out</Menu.Item>
    </Menu>
  );

  if (router.pathname === '/login' || router.pathname.includes('/authenticator') || router.pathname.includes('/register')) return null;

  if (!session) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '100%',
          backgroundColor: 'black',
          paddingTop: '60px',
          paddingBottom: '40px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0px 180px 0px 180px',
            width: getHeaderWidth(props.width)
          }}
        >
          <Link
            href='/'
          >
            <a
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: 60,
                maxWidth: '150px'
              }}
            >
              <Logo />
            </a>
          </Link>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white'
            }}
          >
            <Button
              ghost
              style={{
                borderColor: 'black',
                fontSize: 16,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: 'white',
                  color: 'black'
                }
              }}
              onClick={signIn}
            >
              Sign in / Sign up
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        paddingTop: 30,
        paddingBottom: 15,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
        WebkitBoxShadow: '0 4px 6px -6px #222',
        MozBoxShadow: '0 4px 6px -6px #222',
        boxShadow: '0 4px 6px -6px #222'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: getHeaderWidth(props.width)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: 60
            }}
          >
            <div>
              <img
                src='/Berify-logo.png'
                style={{ width: 50, marginRight: 20 }}
              />
            </div>

            <div>
              <Text style={{ fontSize: 24, color: '#C753FE' }} strong>
                Portal
              </Text>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 20 }}>
              <a href='/'>
                <Text style={{ fontSize: 16, color: 'black' }} strong>
                  Inventory
                </Text>
              </a>
            </div>
          </div>
        </div>
        <div>
          <Dropdown
            overlay={DropdownMenuItems}
            trigger='click'
            placement='bottomRight'
            overlayStyle={{
              paddingTop: 10
            }}
          >
            <a>
              <Avatar
                size={40}icon={<UserOutlined />}
              />
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Header;

// tomorrow's menu
// reskin and fix everything
// Pagination
// refactor and optimize
// Enable image uploads
// Better export data
// QR code generation (for testing purposes of NFC’s)
// Map
// Writing tool for IOS (reads UID and writes it automatically)
// Import token id and UID
